import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

function Foot() {
  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, bgcolor: 'secondary.main' }}>
    <Toolbar>

    </Toolbar>
  </AppBar>

  );
}
export default Foot;